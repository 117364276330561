.App {
    text-align: center;

    --footer-height: 60px;

    word-break: keep-all;

    a {
        color: rgb(150,150,150);
        font-weight: bold;
        font-style: italic;
        text-decoration: inherit;

        &::before {
            content: '↪ ';
        }
    }

    .RootBackground {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;

        img {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;

            object-fit: cover;
            object-position: center;

            &#bg_low_res {
                filter: sepia(10);
            }

            &#bg_high_res {
                transition: 15000ms;

            }
        }
    }

    .Body {
        position: relative;
        height: calc(100vh - var(--footer-height));
        overflow: auto;

        .Content {
            position: absolute;

            top: 0; left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }

    }
    .Footer {
        height: var(--footer-height);
    }

}
